// resources/js/i18n.js
import { createI18n } from 'vue-i18n';

// Import des fichiers de langues
import fr from './i18n/fr_FR.json';
import en from './i18n/en_GB.json';
import es from './i18n/es_ES.json';
import it from './i18n/it_IT.json';
import de from './i18n/de_DE.json';

// Détecter la langue à partir de l'URL
const urlLanguage = window.location.pathname.split('/')[1];

// Détecter la langue stockée dans le localStorage ou utiliser la langue du navigateur par défaut
const storedLanguage = localStorage.getItem('language');
const browserLanguage = navigator.language.slice(0, 2);
const supportedLanguages = ['fr', 'en','es','it','de'];

const defaultLanguage = supportedLanguages.includes(urlLanguage)
    ? urlLanguage
    : supportedLanguages.includes(storedLanguage)
        ? storedLanguage
        : supportedLanguages.includes(browserLanguage)
            ? browserLanguage
            : 'fr';

// Stocker la langue par défaut dans le localStorage pour la consistance
localStorage.setItem('language', defaultLanguage);

// Création de l'instance i18n
const i18n = createI18n({
    locale: defaultLanguage, // Langue par défaut détectée
    fallbackLocale: 'en', // Langue de repli
    messages: {
        fr,
        en,
        es,
        it,
        de,
    },
});

export default i18n;
