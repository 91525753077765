import './bootstrap';
import '../css/app.css';
import Vue3Lazy from 'vue3-lazyload';
import i18n from './i18n';
import VueMatomo from 'vue-matomo'

import { createApp, h } from 'vue';
import { createInertiaApp } from '@inertiajs/vue3';
import { resolvePageComponent } from 'laravel-vite-plugin/inertia-helpers';
import { ZiggyVue } from '../../vendor/tightenco/ziggy';

const appName = import.meta.env.VITE_APP_NAME || 'LKGNtech';

createInertiaApp({
    title: (title) => `${title} - ${appName}`,
    resolve: (name) => resolvePageComponent(`./Pages/${name}.vue`, import.meta.glob('./Pages/**/*.vue')),
    setup({el, App, props, plugin}) {
        const app = createApp({render: () => h(App, props)})
            .use(plugin)
            .use(ZiggyVue)
            .use(i18n)
            .use(VueMatomo, {
                // Configure your matomo server and site by providing
                host: 'https://analytics.lkgntech.com',
                siteId: 1,
            })
            .use(Vue3Lazy, {
                loading: '/images/head_dog_bw_loading.svg',
                error: '/images/head_dog_bw_loading.svg'
            });
        app.mount(el);


        // Exécuter le tracking de la page vue
        window._paq = window._paq || [];
        window._paq.push(['trackPageView']);
        return app;
    },
    progress: {
        color: '#4B5563',
    },
}).then(() => {});
